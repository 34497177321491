<template>
  <v-container
    tag="section"
    fluid
  >
    <files :global="global" />
  </v-container>
</template>

<script>
  export default {
    components: {
      Files: () => import('./Files'),
    },

    props: {
      global: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>
